import jsCookie from 'js-cookie'

// 设置cookie
export const setCookie = (key, value, hours) => jsCookie.set(key, value, hours) // ?
// 获取cookie
export const getCookie = (key) => jsCookie.get(key)
// 获取JSON cookie
export const getJsonCookie = (key) => jsCookie.getJSON(key)
// 清空
export const removeCookie = (key, config = {}) => jsCookie.remove(key, config)

export const tokenKey = 'token'
// 设置token
export const setToken = (value, hours = 8) => setCookie(tokenKey, value, hours)
// 获取token
export const getToken = () => getCookie(tokenKey)
// 清空token
export const removeToken = () => removeCookie(tokenKey)
