<template>
  <div id="default-layout">
    <the-header />
    <div class="page">
      <router-view />
    </div>
    <the-footer />
    <right-flex />
  </div>
</template>

<script>
import theHeader from '@/components/layout/header/Index'
import theFooter from '@/components/layout/Footer'
import RightFlex from '@/components/layout/RightFlex'
export default {
  components: {
    theHeader,
    theFooter,
    RightFlex
  },
  watch: {
    '$route'(to, from) {
      if (to.params.site !== from.params.site) {
        window.location.reload()
      }
    }
  }
}
</script>

<style lang="less" scoped>
#default-layout {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .page {
    flex: 1 0 auto;
  }
}
</style>
