<template>
  <div class="right-flex-wrap">
    <ul class="flex-list">
      <li v-for="(item, index) in flexList" :key="index" class="flex-item">
        <router-link :to="index === 0 ? `/${$route.params.site}/${$route.params.lang}/contactUs` : `/${$route.params.site}/${$route.params.lang}/newsletter-subscription`" class="flex-link">
          <i :class="['icon', 'iconfont', item.icon]"></i>
          <div class="hidden-box">
            <div class="title">{{ item.title }}</div>
            <i :class="['hidden-icon', 'iconfont', item.icon]"></i>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { getRightFlex } from '@/api/public'
export default {
  data() {
    return {
      flexList: [],
      showImg: false,
      secondImg: '',
      showIndex: null
    }
  },
  created() {
    getRightFlex().then(res => {
      this.flexList = res.data
    })
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.right-flex-wrap {
  position: fixed;
  right: 0;
  top: 60%;
  z-index: 100;
  .flex-list {
    .flex-item {
      margin-bottom: 18px;
      background-color: #fff;
      box-shadow: 0px 6px 18px 0px rgba(214,214,214,0.5);
      border-radius: 4px 0px 0px 4px;
      .flex-link {
        display: inline-block;
        display: flex;
        width: 100%;
        height: 100%;
        .icon {
          padding: 10px;
          font-size: 36px;
        }
        .hidden-box {
          display: flex;
          position: absolute;
          right: -300px;
          background-color: #232931;
          border-radius: 4px 0 0 4px;
          .transition;
          .title {
            white-space: nowrap;
            padding: 16px;
            font-size: 18px;
            font-weight: bold;
            color: #fff;
            line-height: 24px;
            border-radius: 4px 0px 0px 4px;
          }
          .hidden-icon {
            color: #fff;
            padding: 10px;
            font-size: 36px;
          }
        }
      }
      &:hover {
        background-color: #232931;
        box-shadow: none;
        .flex-link {
          .hidden-box {
            right: 0;
          }
        }
      }
    }
  }
}
</style>
