<template>
  <header>
    <div :class="['header-pc', {'white-header' : whiteHeader || navShow || showDropDown !== -1}]">
      <div v-show="navOff" class="header-box container">
        <div class="logo-box">
          <router-link :to="`/${$route.params.site}/${$route.params.lang}`" class="logo-link">
            <img v-if="whiteHeader || navShow || showDropDown !== -1" src="@/assets/img/home/LOGO.png" alt="" />
            <img v-else src="@/assets/img/home/logo-f.png" alt="" />
          </router-link>
        </div>
        <div class="nav-box">
          <ul class="nav-list">
            <li v-for="(item, index) in headerInfo" :key="index" class="list-item" @mouseenter="handlerEnter(item, index)" @mouseleave="handlerLeave()">
              <router-link
                :to="`/${$route.params.site}/${$route.params.lang}${item.nav_linkurl}`"
                class="item-link"
              >
                {{ item.nav_name }}
              </router-link>
              <el-collapse-transition>
                <div v-if="showDropDown === index && headerInfo.length - 1 !== index && headerInfo[index].child.length > 0" class="drop-down">
                  <div class="container product-drop-down">
                    <div class="product-content">
                      <div class="content-title">
                        {{ item.nav_name }}
                      </div>
                      <div class="content-item">
                        <el-tabs v-if="index === 0 || item.nav_id === '7'" tab-position="left">
                          <el-tab-pane v-for="(i, index) in item.child" :key="index">
                            <a v-if="i.menu_keywords" slot="label" :href="i.menu_keywords" target="_blank">
                              <span @mouseenter="tabChange(i)">
                                {{ i.nav_name }}
                                <i :class="['icon', {'el-icon-arrow-right' : i.child.length}]"></i>
                              </span>
                            </a>
                            <router-link v-else slot="label" :to="`/${$route.params.site}/${$route.params.lang}${i.nav_linkurl}`">
                              <span @mouseenter="tabChange(i)">
                                {{ i.nav_name }}
                                <i :class="['icon', {'el-icon-arrow-right' : i.child.length}]"></i>
                              </span>
                            </router-link>
                            <div v-for="(e, index) in tabsList" :key="index" class="pane-item">
                              <router-link :to="`/${$route.params.site}/${$route.params.lang}${e.nav_linkurl}`" class="pane-link">{{ e.nav_name }}</router-link>
                            </div>
                          </el-tab-pane>
                        </el-tabs>
                        <div v-else>
                          <div v-for="(e, index) in item.child" :key="index" class="support-item">
                            <router-link
                              :to="`/${$route.params.site}/${$route.params.lang}${e.nav_linkurl}`"
                              class="support-link"
                            >
                              {{ e.nav_name }}
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="showImg" class="product-img">
                      <img :src="showImg" alt="" />
                    </div>
                  </div>
                </div>
              </el-collapse-transition>
            </li>
          </ul>
        </div>
        <div class="right-box">
          <div class="change-business">
            <router-link :to="isToB ? `/business/${$route.params.lang}` : `/consumer/${$route.params.lang}`" class="change-link">
              {{ isToB ? LP.lang_for_business : LP.lang_for_consumer }}
            </router-link>
          </div>
          <!-- 语言下拉 -->
          <div class="lang-box" @mouseover="handleOver" @mouseleave="handleLeave">
            <i class="icon iconfont icon-a-language1x"></i>
            <span class="show-lang ellipsis">{{ language }}</span>
            <el-collapse-transition>
              <div v-if="showLang" class="lang-drop">
                <ul class="lang-list">
                  <li v-for="(item, index) in lang" :key="index" class="lang-item">
                    <span class="lang-link ellipsis" @click="langHandler(item)">
                      {{ item.language_name }}
                    </span>
                  </li>
                </ul>
              </div>
            </el-collapse-transition>
          </div>
          <!-- 登录 -->
          <template>
            <div v-if="!userName" class="user-box" @click="handleLogin">
              <i class="icon iconfont icon-a-gerenzhongxin1x"></i>
            </div>
            <div v-else class="user-box user-logged">
              <el-dropdown>
                <span class="el-dropdown-link">
                  <i class="icon iconfont icon-a-gerenzhongxin-logged1x"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <div class="user-logged-select">
                    <div class="login-head-info">
                      <div class="header-img">
                        <span class="iconfont icon-geren2"></span>
                        <div class="text-box">
                          <h3 class="title ellipsis">{{ userName }}</h3>
                          <p class="desc ellipsis">{{ daHuaUserEmail }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="login-list">
                      <a class="link" @click="handleLogout">
                        <span class="iconfont icon-tuichu"></span>{{ LP.lang_logout }}
                      </a>
                    </div>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
          <!-- 搜索 -->
          <div class="search-box" @click="showMaskHandler()">
            <i class="icon el-icon-search"></i>
          </div>
        </div>
        <div v-if="showMask" class="mask-box">
          <div class="search-info-box container">
            <div class="close-box clearfix" @click="closeBoxHandler">
              <i class="iconfont icon-shanchu_1"></i>
            </div>
            <div class="search-title">
              {{ LP.lang_search_tips }}
            </div>
            <div class="search-input">
              <el-autocomplete
                v-model.trim="keyword"
                class="inline-input"
                :fetch-suggestions="querySearch"
                :placeholder="LP.lang_search"
                :trigger-on-focus="false"
                @select="handleSearch"
                @keyup.enter.native="handleSearch(keyword)"
              >
                <i
                  slot="prefix"
                  class="icon iconfont icon-a-search1x"
                  @click="handleSearch(keyword)"
                >
                </i>
                <i
                  v-if="keyword"
                  slot="suffix"
                  class="iconfont icon-shanchu_1 icon"
                  @click="clearHandler"
                >
                </i>
              </el-autocomplete>
            </div>
            <div class="search-tag">
              <el-tag v-for="(item, index) in tagList" :key="index" @click="handleSearch(item.keyword_name)">{{ item.keyword_name }}</el-tag>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import { getSearchHeat } from '@/api/search'
import { getCookie, removeCookie } from '@/utils/cookie'
export default {
  props: {
    headerInfo: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isToB: true, // B 和 C站点按钮区分显示
      top: 0, // 导航到页面顶部距离
      navShow: false, // 下滑导航栏
      navOff: true, // 上滑导航栏
      showDropDown: -1, // 显示对应下拉
      restaurants: [],
      keyword: '',
      showMask: false,
      tagList: [],
      showLang: false, // 显示多语言
      language: '', // 显示语言
      showIndex: 0,
      showImg: '',
      langCode: '', // 切换语言code
      tabsList: [], // 产品下拉tabs列表
      userName: '', // 用户名
      daHuaUserEmail: '' // 邮箱
    }
  },
  computed: {
    ...mapState({
      lang: state => state.common.lang
    }),
    // 是否显示白色导航
    whiteHeader() {
      return this.$store.state.header.whiteHeader
    }
  },
  watch: {
    top(newVal, oldVal) {
      newVal > oldVal ? this.navShow = true : this.navShow = false
      if (!this.showMask) {
        !this.navShow && this.top > 0 ? this.navOff = false : this.navOff = true
      }
    },
    '$route': {
      handler(val) {
        if (val.fullPath.includes('business')) {
          this.isToB = false
        } else {
          this.isToB = true
        }
        this.keyword = ''
        this.showDropDown = -1
      },
      immediate: true,
      deep: true
    }
  },
  async mounted() {
    // await this.$store.dispatch('common/getLang')
    const langItem = this.lang.filter(item => item.language_code === this.$route.params.lang)
    if (langItem.length > 0) {
      // this.language = langItem[0].language_name.split('-')[0]
      this.language = langItem[0].language_name
    } else {
      // this.language = this.lang[0].language_name.split('-')[0]
      this.language = this.lang[0].language_name
    }
    // 监听页面到顶部距离  显示与隐藏头部导航
    window.addEventListener('scroll', () => {
      this.top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
    })
    this.restaurants = this.loadAll()
    // 获取登录信息
    this.userName = getCookie('logoEmail')
    this.daHuaUserEmail = getCookie('daHuaUserEmail')
  },
  methods: {
    // 展开下拉导航
    handlerEnter(item, index) {
      this.showDropDown = index
      if (this.headerInfo.length - 1 !== index && this.headerInfo[index].child.length > 0) {
        this.showImg = this.headerInfo[index].child[0].nav_image
      }
      if (index === 0) {
        this.tabsList = item.child[0].child
      } else {
        this.tabsList = []
      }
    },
    // 离开下拉导航
    handlerLeave() {
      this.showDropDown = -1
    },
    // 产品tabs下拉切换
    tabChange(i) {
      this.showImg = i.nav_image
      this.tabsList = i.child
    },
    // 获取语言
    langHandler(item) {
      if (item.language_code) {
        this.language = item.language_name
        this.langCode = item.language_code
      } else {
        this.langCode = 'en'
      }
      window.open(`/${this.$route.params.site}/${this.langCode}`, '_self')
    },
    // 跳转海外官网登录页
    handleLogin() {
      window.open(`https://www.dahuasecurity.com${this.langCode}/user/login`, '_self')
    },
    showMaskHandler() {
      this.showMask = true
      getSearchHeat().then(res => {
        this.tagList = res.data
      })
    },
    // 输入是返回的数据
    querySearch(queryString, cb) {
      var restaurants = this.restaurants
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    // 返回识别正确哪一项
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant
      }
    },
    // 初始input关键字列表
    loadAll() {
      return ['youtube']
    },
    // 搜索
    handleSearch(keyword) {
      if (keyword) {
        this.keyword = keyword
        this.$router.push({
          path: `/${this.$route.params.site}/${this.$route.params.lang}/search`,
          query: {
            keyword: keyword
          }
        })
        this.showMask = false
      } else {
        this.$message.error(this.LP.lang_enter_keywords)
      }
    },
    closeBoxHandler() {
      this.showMask = false
      this.keyword = ''
    },
    // 清除搜索框
    clearHandler() {
      this.keyword = ''
    },
    // 退出登录
    handleLogout() {
      const config = {
        path: '/',
        domain: '.dahuasecurity.com'
      }
      removeCookie('setAuthUser', { ...config }) // 删除cookie
      removeCookie('userName', { ...config }) // 删除userName
      removeCookie('useremail', { ...config }) // 删除cookie
      removeCookie('setUseremail', { ...config }) // 删除cookie
      removeCookie('operation', { ...config }) // 删除cookie
      removeCookie('userId', { ...config }) // 删除cookie
      removeCookie('itr', { ...config }) // 删除cookie
      removeCookie('logoEmail', { ...config }) // 删除logoMake email
      removeCookie('logoId', { ...config }) // 删除logoMake Id
      window.open(`/${this.$route.params.site}/${this.$route.params.lang}`, '_self')
    },
    // 鼠标移入语言下拉
    handleOver() {
      this.showLang = true
    },
    // 鼠标移出语言下拉
    handleLeave() {
      this.showLang = false
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.header-pc {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
  .header-box {
    display: flex;
    .logo-box {
      align-self: center;
      max-width: 128px;
      .logo-link {
        display: inline-block;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
        }
      }
    }
    .nav-box {
      width: 57.5%;
      margin-left: 5.3%;
      flex: 1;
      .nav-list {
        display: flex;
        .list-item {
          padding: 0 24px;
          .item-link {
            font-size: 16px;
            font-weight: 600;
            color: #fff;
            line-height: 21px;
            display: inline-block;
            padding: 28px 0 ;
            border-bottom: 2px solid transparent;
            .transition;
          }
          &:hover {
            .item-link {
              color: @theme;
              border-bottom: 2px solid #232931;
            }
          }
          .drop-down {
            position: absolute;
            top: 79px;
            left: 0;
            width: 100%;
            z-index: 1001;
            background-color: #fff;
            border-top: 1px solid #ddd;
            .product-drop-down {
              padding: 48px 0;
              display: flex;
              .product-content {
                width: 67%;
                .content-title {
                  margin-bottom: 24px;
                  font-size: 24px;
                  font-weight: 600;
                  color: #232931;
                  line-height: 32px;
                }
                .content-item {
                  padding-right: 54px;
                  .support-item {
                    width: 49.7%;
                    border-radius: 10px;
                    cursor: pointer;
                    .transition;
                    .support-link {
                      padding: 8px 16px;
                      display: inline-block;
                      width: 100%;
                      font-size: 16px;
                      color: #333333;
                      line-height: 16px;
                      .transition;
                    }
                    &:hover {
                      background-color: #F4F4F5;
                      .support-link {
                        color: @theme;
                      }
                    }
                  }
                }
              }
              .product-img {
                width: 33%;
                max-height: 304px;
                padding-left: 69px;
                border-left: 1px solid #ddd;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
    .right-box {
      justify-self: end;
      display: flex;
      align-items: center;
      // padding: 22px 0 23px;
      .change-business {
        padding: 8px 16px;
        border: 1px solid #ccc;
        border-radius: 16px;
        .transition();
        .change-link {
          display: inline-block;
          width: 100%;
          height: 100%;
          font-size: 16px;
          font-weight: 600;
          color: #fff;
          .transition();
        }
        &:hover {
          background-color: #fff;
          .change-link {
            color: #232931;
          }
        }
      }
      .lang-box {
        cursor: pointer;
        margin: 0 16px;
        padding: 22px 0;
        .icon {
          vertical-align: middle;
        }
        .show-lang {
          vertical-align: middle;
          display: inline-block;
          margin-left: 8px;
          width: 80px;
          max-height: 32px;
          font-size: 14px;
          color: #fff;
          .transition;
        }
        .lang-drop {
          position: absolute;
          top: 79px;
          transform: translateX(-25%);
          min-width: 240px;
          background-color: #fff;
          padding-top: 10px;
          box-shadow: 0 0 16px rgba(0,0,0,.15);
          .lang-list {
            max-height: 460px;
            overflow-y: auto;
            .lang-item {
              .lang-link {
                display: inline-block;
                width: 100%;
                height: 100%;
                text-align: center;
                padding: 10px 20px;
                font-size: 16px;
                color: #333333;
                line-height: 18px;
                .transition;
                &:hover {
                  background-color: #f5f5f5;
                  color: @theme;
                }
              }
            }
          }
          &::before {
            content: "";
            display: block;
            position: absolute;
            top: -10px;
            left: 50%;
            transform: translateX(-50%);
            border-bottom: 10px solid #fff;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            z-index: 0;
          }
        }
      }
      .user-box {
        cursor: pointer;
        margin-right: 24px;
      }
      .search-box {
        cursor: pointer;
      }
      .lang-box,
      .user-box,
      .search-box {
         .icon {
           color: #fff;
           font-size: 20px;
          .transition;
         }
         &:hover {
           .icon {
             color: @theme;
           }
           .show-lang {
             color: @theme;
           }
         }
      }
      .user-logged {
        .icon {
          color: @theme !important;
        }
      }
    }
    .mask-box {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 998;
      width: 100%;
      height: 100%;
      background-color: rgba(35, 41, 49, 0.88);
      .search-info-box {
        padding-top: 87px;
        .close-box {
          float: right;
          cursor: pointer;
          .transition();
          .iconfont {
            font-size: 32px;
            color: #909399;
          }
          &:hover {
            transform: rotate(-90deg);
          }
        }
        .search-title {
          margin: 41px auto 24px;
          text-align: center;
          font-size: 24px;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 32px;
        }
        .search-input {
          text-align: center;
          margin-bottom: 24px;
          /deep/ .el-autocomplete {
            width: 632px;
            .el-input {
              .el-input__inner {
                border-radius: 28px;
                padding: 0 28px 0 72px;
                height: 54px;
                border: 1px solid #909399;
                font-size: 18px;
                color: #303133;
                line-height: 24px;
                &::placeholder {
                  color: #979797;
                }
              }
              .el-input__prefix {
                cursor: pointer;
                left: 28px;
                .icon {
                  font-size: 32px;
                  color: #242A32;
                  line-height: 54px;
                }
              }
              .el-input__suffix {
                cursor: pointer;
                right: 28px;
                 .icon {
                  font-size: 24px;
                  color: #909399;
                  line-height: 54px;
                }
              }
            }
          }
        }
        .search-tag {
          text-align: center;
          .el-tag {
            cursor: pointer;
            margin-right: 15px;
            padding: 6px 17px;
            border-radius: 14px;
            background-color: rgba(255,255,255,0);
            border: 1px solid rgba(255,255,255,0.5);
            font-size: 14px;
            color: #FFFFFF;
            line-height: 16px;
            .transition;
            &:hover {
              color: @theme;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1320px) {
    .header-box {
      .nav-box {
        width: 47%;
        margin-left: 2%;
        .nav-list {
          .list-item {
            padding: 0 12px;
          }
        }
      }
    }
  }
}
.white-header {
  background-color: #fff;
  .header-box {
    .nav-box {
      .nav-list {
        .list-item {
          .item-link {
            color: #232931;
          }
        }
      }
    }
    .right-box {
      .change-business {
        .change-link {
          color: #232931;
        }
        &:hover {
          background-color: #000;
          .change-link {
            color: #fff;
          }
        }
      }
      .lang-box {
        .show-lang {
          color: #232931;
        }
      }
      .lang-box,
      .user-box,
      .search-box {
        .icon {
          color: #232931;
        }
      }
    }
  }
}
/deep/ .el-tabs.el-tabs--left {
  display: flex;
  justify-content: space-between;
  .el-tabs__header.is-left {
    margin-right: 70px;
    flex: 1;
    .el-tabs__nav-wrap.is-left {
      &::after {
        display: none;
      }
      .el-tabs__item.is-left {
        height: 100%;
        text-align: left;
        font-size: 16px;
        color: #333333;
        font-weight: normal;
        line-height: 16px;
        span {
          display: inline-block;
          padding: 8px 30px 8px 37px;
          width: 100%;
          border-radius: 10px;
          .transition;
          .icon {
            float: right;
          }
          &:hover {
             background-color: #f5f5f5;
             color: @theme;
          }
        }
      }
    }
  }
  .el-tabs__content {
    flex: 1;
    .el-tab-pane {
      .pane-item {
        cursor: pointer;
        align-self: center;
        border-radius: 10px;
        .transition;
        .pane-link {
          padding: 6px 0 6px 16px;
          display: inline-block;
          width: 100%;
          font-size: 16px;
          color: #333333;
          line-height: 18px;
          .transition;
        }
        &:hover {
          background-color: #f5f5f5;
          .pane-link {
            color: @theme;
          }
        }
      }
    }
  }
}
/deep/ .el-tabs__item {
  padding: 0;
}
/deep/ .el-tabs--left .el-tabs__active-bar.is-left {
  display: none;
}
// 登录下拉
/deep/ .user-logged-select {
  .login-head-info {
    .header-img {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .iconfont {
        display: block;
        font-size: 42px;
        line-height: 1;
        color: #999;
      }
      .text-box {
        width: calc(100% - 52px);
        max-width: 160px;
        .title {
          font-size: 14px;
          line-height: 24px;
        }
        .desc {
          margin-top: 5px;
          font-size: 12px;
          line-height: 22px;
          color: #666;
        }
      }
    }
  }
  .login-list {
    cursor: pointer;
    .link {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      white-space: nowrap;
      line-height: 1;
      color: #666;
      .iconfont {
        margin-right: 8px;
      }
    }
    &:hover {
      background-color: #f5f5f5;
    }
  }
}
/deep/ .el-popper{
  display: none;
}
</style>
