<template>
  <ul class="mobile-nav-item">
    <li v-for="(item, index) in list" :key="index" class="item-li">
      <a :class="{ active: showChildList === index }" class="link">
        <span class="name" @click="linkHandle(item, index)">{{ item.nav_name }}</span>
        <span
          v-if="item.child && item.child.length"
          class="iconfont icon-a-xiala1x"
          :class="{unfold: showChildList === index}"
          @click.stop="handleClick(item, index)"
        ></span>
      </a>
      <!-- 子集菜单 -->
      <el-collapse-transition>
        <header-slide-down
          v-if="item.child && item.child.length"
          v-show="showChildList === index"
          :list="item.child"
          :show="false"
        />
      </el-collapse-transition>
    </li>
    <!-- 登录 -->
    <li v-if="show" class="item-li">
      <div :class="{ active: showLoginList}" class="link" @click="handleLogin">
        <span class="name">{{ userName ? LP.lang_my_dahua : LP.lang_login }}</span>
        <span
          v-if="userName"
          class="iconfont icon-a-xiala1x"
          :class="{unfold: showLoginList}"
        ></span>
      </div>
      <!-- 子集菜单 -->
      <el-collapse-transition>
        <ul v-if="showLoginList">
          <li class="item-li" @click="handleLogout">
            <div class="link">
              {{ LP.lang_sign_out }}
            </div>
          </li>
        </ul>
      </el-collapse-transition>
    </li>
    <!-- 语言下拉 -->
    <li v-if="show" class="item-li">
      <div :class="{ active: showLangList}" class="link" @click="langHandler">
        <span class="name">{{ LP.lang_language }}</span>
        <span
          class="iconfont icon-a-xiala1x"
          :class="{unfold: showLangList}"
        ></span>
      </div>
      <!-- 子集菜单 -->
      <el-collapse-transition>
        <ul v-if="showLangList">
          <li v-for="(item, index) in lang" :key="index" class="item-li" @click="changeLang(item)">
            <div class="link">
              {{ item.language_name }}
            </div>
          </li>
        </ul>
      </el-collapse-transition>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex'
import { getCookie, removeCookie } from '@/utils/cookie'
export default {
  name: 'HeaderSlideDown',
  props: {
    // 菜单列表
    list: {
      type: Array,
      default: () => []
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showChildList: -1, // 是否显示一级菜单
      showLangList: false,
      langCode: '',
      showLoginList: false,
      userName: '', // 用户名
      daHuaUserEmail: '' // 邮箱
    }
  },
  computed: {
    ...mapState({
      lang: state => state.common.lang
    })
  },
  mounted() {
    // 获取登录信息
    this.userName = getCookie('logoEmail')
    this.daHuaUserEmail = getCookie('daHuaUserEmail')
  },
  methods: {
    // 点击导航显示
    handleClick(item, index) {
      if (item.child && item.child.length) {
        index === this.showChildList ? this.showChildList = -1 : this.showChildList = index
      }
    },
    // 点击跳转
    linkHandle(item, index) {
      this.$router.push({
        path: `/${this.$route.params.site}/${this.$route.params.lang}${item.nav_linkurl}`
      })
      this.$emit('closeMobileList')
    },
    // 获取语言
    async langHandler() {
      await this.$store.dispatch('common/getLang')
      this.showLangList = !this.showLangList
    },
    // 语言切换
    changeLang(item) {
      this.langCode = item.language_code
      this.$router.push(`/${this.$route.params.site}/${this.langCode}`)
      window.location.reload()
    },
    // 跳转海外官网登录页
    handleLogin() {
      if (this.userName) {
        this.showLoginList = !this.showLoginList
      } else {
        window.open(`https://www.dahuasecurity.com${this.langCode}/user/login`, '_self')
      }
    },
    // 退出登录
    handleLogout() {
      const config = {
        path: '/',
        domain: '.dahuasecurity.com'
      }
      removeCookie('setAuthUser', { ...config }) // 删除cookie
      removeCookie('userName', { ...config }) // 删除userName
      removeCookie('useremail', { ...config }) // 删除cookie
      removeCookie('setUseremail', { ...config }) // 删除cookie
      removeCookie('operation', { ...config }) // 删除cookie
      removeCookie('userId', { ...config }) // 删除cookie
      removeCookie('itr', { ...config }) // 删除cookie
      removeCookie('logoEmail', { ...config }) // 删除logoMake email
      removeCookie('logoId', { ...config }) // 删除logoMake Id
      window.open(`/${this.$route.params.site}/${this.$route.params.lang}`, '_self')
    }
  }
}
</script>

<style lang="less" scoped>
.mobile-nav-item {
  .item-li {
    .link {
      display: block;
      padding-left: 3%;
      -webkit-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
      position: relative;
      height: 36px;
      font-size: 16px;
      color: #909399;
      line-height: 36px;
      border-bottom: 1px solid #ddd;
      display: flex;
      justify-content: space-between;
      .name {
        flex: 4.5;
      }
      .iconfont {
        flex: 0.5;
        text-align: center;
      }
      &.active {
        color: #232931;
        .unfold {
          transform: rotate(180deg);
        }
      }
    }
		// 子集面板缩进
		.item-li{
			.link{
				padding-left:5%;
			}
			.item-li{
				.link{
					padding-left:7%;
				}
			}
		}
  }
	@media screen and (max-width:768px) {
		.item-li{
			.link{
				padding: 0 4%;
			}
			.item-li{
				.link{
					padding-left:8%;
				}
				.item-li{
					.link{
						padding-left:12%;
					}
				}
			}
		}
	}
}
</style>
