<template>
  <div :class="['header-mobile-wrap', {'height' : showMobileList === true}]">
    <div class="site-box">
      <ul class="site-list">
        <li class="site-item">
          <router-link :to="`/consumer/${$route.params.lang}`" :class="['site-link' ,{'active' : $route.params.site === 'business'}]">
            {{ LP.lang_for_consumer }}
          </router-link>
        </li>
        <li class="site-item">
          <router-link :to="`/business/${$route.params.lang}`" :class="['site-link', {'active' : $route.params.site === 'consumer'}]">
            {{	LP.lang_for_business }}
          </router-link>
        </li>
      </ul>
    </div>
    <div class="header-container container">
      <router-link :to="`/${$route.params.site}/${$route.params.lang}`" class="logo-box fl">
        <img class="logo-img" src="@/assets/img/home/LOGO.png" alt="" />
      </router-link>
      <ul class="mobile-nav fr clearfix">
        <li
          class="header-info--full header-mobile-nav visible-sm"
          :class="{ active: showMobileList }"
          @click="showMobileList = !showMobileList"
        >
          <div class="mobile-nav-icon">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </li>
        <!-- 搜索 -->
        <li
          ref="searchBox"
          class="header-info--full model-search"
          :class="{ active: showSearchBox }"
          @click="showSearchBox = !showSearchBox"
        >
          <span class="iconfont icon-a-search1x"></span>
        </li>
      </ul>
      <!-- 搜索框 -->
      <transition name="el-fade-in-linear">
        <div
          v-show="showSearchBox"
          ref="searchInput"
          class="model-search-box"
        >
          <input
            ref="homeInputVal"
            v-model="keyword"
            class="search-input"
            :placeholder="LP.lang_search"
            @keyup.enter="handleSearch(keyword)"
          />
          <button
            class="search-btn"
            @click="handleSearch(keyword)"
          >
            <span class="search-btn-icon iconfont icon-a-search1x"></span>
          </button>
        </div>
      </transition>
    </div>
    <!-- 菜单列表 -->
    <div v-if="showMobileList" class="header-slide-down">
      <header-slide-down :list="headerInfo" @closeMobileList="closeMobileList" />
    </div>
  </div>
</template>

<script>
import HeaderSlideDown from '@/components/layout/header/HeaderSlideDown.vue'
export default {
  components: {
    HeaderSlideDown
  },
  props: {
    headerInfo: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showMobileList: false,
      showSearchBox: false,
      keyword: ''
    }
  },
  watch: {
    '$route' (val) {
      if (val) {
        this.showMobileList = false
      }
    }
  },
  methods: {
    // 打开nav框
    closeMobileList() {
      this.showMobileList = false
    },
    // 搜索
    handleSearch(keyword) {
      this.keyword = keyword
      this.$router.push({
        path: `/${this.$route.params.site}/${this.$route.params.lang}/search`,
        query: {
          keyword: keyword
        }
      })
      this.showSearchBox = false
      this.keyword = ''
    }
  }
}
</script>

<style lang="less" scoped>
.header-mobile-wrap {
   position: fixed;
   width: 100%;
   top: 0;
   left: 0;
   z-index: 1001;
   background-color: #fff;
  .site-box {
    border-bottom: 1px solid #ddd;
    .site-list {
      display: flex;
      justify-content: space-between;
      .site-item {
        flex: 1;
        .site-link {
          display: inline-block;
          width: 100%;
          padding: 8px 0;
          text-align: center;
          font-size: 14px;
          font-weight: 600;
          color: #232931;
          line-height: 16px;
          background-color: #fff;
        }
        .active {
          background-color: #f5f5f5;
          color: #979797;
        }
      }
    }
  }
  .header-container {
    padding: 0 9px;
    background: #FFFFFF;
    border-bottom: 1px solid #ddd;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    .logo-box {
      max-width: 100px;
      line-height: 60px;
      .logo-img {
        max-width: 100%;
      }
    }
     .mobile-nav {
      max-width: 50%;
      float: right;
      padding-right: 11px;
      & > li {
        float: left;
        position: relative;
        font-size: 14px;
        z-index: 99;
        .mobile-nav-icon {
          width: 22px;
          height: 16px;
          margin: 21px auto;
          position: relative;
          span {
            display: block;
            width: 100%;
            height: 2px;
            position: absolute;
            left: 0;
            background-color: #333;
            border-radius: 4px;
            -webkit-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
          }
          span:first-child {
            top: 0;
            top: 50%;
          }
          span:nth-child(2) {
            bottom: -2px;
          }
        }
      }
      .header-info--full {
        width: 40px;
        margin-left: 1px;
        color: #fff;
        text-align: center;
        cursor: pointer;
        float: right;
        -webkit-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
        transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
      }
      .model-search {
        line-height: 60px;
        & > .iconfont {
          color: #333;
          font-size: 24px;
        }
      }
      .header-mobile-nav {
        &.active {
          transition: all 0.8s;
          .mobile-nav-icon {
            span:first-child {
              transform: rotate(45deg) translateY(-50%);
              transform-origin: center;
              top: 50%;
            }
            span:nth-child(2) {
              opacity: 0;
            }
            span:last-child {
              transform: rotate(-45deg) translateY(-50%);
              transform-origin: center;
              top: 50%;
            }
          }
        }
      }
    }
    .model-search-box {
      width: 350px;
      padding: 20px 24px;
      position: absolute;
      top: 92px;
      right: 0;
      -webkit-box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
      z-index: 999;
      background-color: #fff;
      overflow-y: auto;
      .search-input {
        width: 100%;
        height: 36px;
        background-color: #fff;
        border: 1px solid #ccc;
        padding: 0 40px 0 12px;
        -webkit-transition: all .8s cubic-bezier(.19,1,.22,1);
        transition: all .8s cubic-bezier(.19,1,.22,1);
      }
      .search-btn {
        width: 40px;
        height: 36px;
        position: absolute;
        right: 24px;
        top: 20px;
        background-color: @theme;
        color: #fff;
        cursor: pointer;
        -webkit-transition: all .8s cubic-bezier(.19,1,.22,1);
        transition: all .8s cubic-bezier(.19,1,.22,1);
      }
    }
  }
  .header-slide-down {
    height: 100%;
  }
}
.height {
  height: 100%;
  overflow-y: auto;
}
</style>
