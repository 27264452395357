<template>
  <footer>
    <div class="footer-wrap">
      <div class="footer-top container">
        <div class="left-list">
          <dl v-for="(item, index) in footerInfo" :key="index" class="dl-list">
            <dt class="dt-item ellipsis">
              <router-link :to="`/${$route.params.site}/${$route.params.lang}${item.nav_linkurl}`" class="dt-link">
                {{ item.nav_name }}
              </router-link>
            </dt>
            <dd v-for="(e, index) in item.child" :key="index" class="dd-item ellipsis">
              <router-link class="item-link" :to="`/${$route.params.site}/${$route.params.lang}${e.nav_linkurl}`">
                {{ e.nav_name }}
              </router-link>
            </dd>
          </dl>
        </div>
        <div class="right-list">
          <ul class="ul-list">
            <li v-for="(item, index) in rightList" :key="index" class="list-item">
              <a class="item-link" :href="item.link" target="_blank" rel="nofollow noopener noreferrer">{{ item.title }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="bottom-content container">
          <div class="content-left">
            <div class="sign">{{ LP.lang_dahua_technology }}</div>
            <div class="record">
              <span>
                <a :href="LP.lang_beian_two_url" target="_blank" rel="nofollow noopener noreferrer">
                  {{ LP.lang_beian_two }}
                </a>
              </span>
              <span>
                <a :href="LP.lang_beian_three_url" target="_blank" rel="nofollow noopener noreferrer">
                  {{ LP.lang_beian_three }}
                </a>
              </span>
            </div>
          </div>
          <div class="content-right">
            <div v-for="(item, index) in otherList" :key="index" class="other-box">
              <a :href="item.follow_linkurl" class="other-link" target="_blank" rel="nofollow noopener noreferrer">
                <span :class="['iconfont', item.iconfont]"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import { getFollow } from '@/api/public'
export default {
  data() {
    return {
      rightList: [
        {
          title: this.LP.lang_dahua_tech,
          link: `https://www.dahuasecurity.com/${this.$route.params.lang}`
        },
        {
          title: this.LP.lang_terms_of_use,
          link: `https://www.dahuasecurity.com/${this.$route.params.lang}/aboutUs/terms-of-use/`
        },
        {
          title: this.LP.lang_privacy_policy,
          link: `https://www.dahuasecurity.com/${this.$route.params.lang}/aboutUs/privacy-policy`
        }
      ],
      otherList: []
    }
  },
  computed: {
    ...mapState({
      footerInfo: state => state.common.footerInfo
    })
  },
  async mounted() {
    await this.$store.dispatch('common/getPublcNav', 'footer')
    this.getFollow()
  },
  methods: {
    // 获取社交媒体
    getFollow() {
      getFollow().then(res => {
        this.otherList = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.footer-wrap {
  background-color: #232931;
  .footer-top {
    display: flex;
    padding-top: 32px;
    .left-list {
        flex: 3;
        display: flex;
        .dl-list {
          width: 20.7%;
          margin-right: 18px;
          .dt-item {
            margin-bottom: 16px;
            .dt-link {
              font-size: 14px;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 19px;
              .transition();
            }
            &:hover {
              .dt-link {
                color: @theme;
              }
            }
          }
          .dd-item {
            margin-bottom: 16px;
            .item-link {
              font-size: 14px;
              color: #CCCCCC;
              line-height: 16px;
              .transition();
            }
            &:hover {
              .item-link {
                color: @theme;
              }
            }
          }
        }
    }
    .right-list {
        flex: 1;
        margin-bottom: 92px;
        .ul-list {
          .list-item {
            margin-bottom: 16px;
            .item-link {
              font-size: 14px;
              color: #FFFFFF;
              line-height: 24px;
              .transition();
              &:hover {
                color: @theme;
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
    }
  }
  .footer-bottom {
    border-top: 1px solid #606266;
    padding: 15px 0 16px;
    .bottom-content {
      display: flex;
      justify-content: space-between;
      .content-left {
        font-size: 16px;
        font-weight: 400;
        color: #606266;
        line-height: 22px;
        .record {
          margin-top: 16px;
          span {
            a {
              font-size: 16px;
              font-weight: 400;
              color: #606266;
              line-height: 22px;
              .transition();
              &:hover {
                color: @theme;
              }
            }
            margin-right: 24px;
          }
        }
      }
      .content-right {
        display: flex;
        align-items: center;
        .other-box {
          width: 30px;
          height: 30px;
          margin-left: 24px;
          .other-link {
            display: inline-block;
            width: 100%;
            height: 100%;
            background-color: #323232;
            border-radius: 50%;
            text-align: center;
            .iconfont {
              line-height: 30px;
              font-size: 14px;
              color: #ddd;
              .transition();
            }
            &:hover {
              .iconfont {
                color: @theme;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .footer-top {
      display: block;
      padding: 20px 0;
      .left-list {
        display: none;
      }
      .right-list {
        margin-bottom: 0;
        .ul-list {
          display: flex;
          justify-content: center;
          .list-item {
            margin-bottom: 0;
            border-right: 1px solid #606266;
            .item-link {
              padding: 0 8px;
            }
            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }
    .footer-bottom {
      height: 150px;
      padding: 23px 0 25px;
      .bottom-content {
        display: block;
        .content-left {
          transform: translateY(54px);
          text-align: center;
          font-size: 12px;
          line-height: 16px;
          .record {
            margin-top: 15px;
            span {
              a {
                font-size: 12px;
                line-height: 17px;
              }
            }
          }
        }
        .content-right {
          transform: translateY(-54px);
          justify-content: center;
        }
      }
    }
  }
}
</style>
