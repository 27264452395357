<template>
  <div class="header-wrap">
    <header-Pc class="show-header-pc" :header-info="headerInfo" />
    <header-mobile class="show-header-mobile" :header-info="headerInfo" />
  </div>
</template>

<script>
import HeaderMobile from '@/components/layout/header/HeaderMobile.vue'
import HeaderPc from '@/components/layout/header/HeaderPc.vue'
import { mapState } from 'vuex'
export default {
  components: {
    HeaderPc,
    HeaderMobile
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      headerInfo: state => state.common.headerInfo
    })
  },
  async created() {
    await this.$store.dispatch('common/getPublcNav', 'header')
  }
}
</script>

<style lang="less" scoped>
.header-wrap {
  .show-header-mobile {
    display: none;
  }
  @media screen and (max-width: 1024px) {
    .show-header-mobile {
      display: block;
    }
    .show-header-pc {
      display: none;
    }
  }
}
</style>
